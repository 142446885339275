import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Table, Button} from "react-bootstrap";
import {act_loadPaygreenSync} from "../../actions";
import moment from "moment";
import {SUPPORT_CANCEL_PAYGREEN_TRANSACTION} from "../../actions";

class InternalPaygreenList extends React.Component {
  componentDidMount() {
    if (this.props.user && this.props.user.id) {
      this.props.loadPaygreenSync(this.props.user.id);
    }
  }

  componentDidUpdate() {
    if (typeof(this.props.paygreen.shouldRefresh) !== 'undefined' && this.props.paygreen.shouldRefresh === true) {
      this.props.loadPaygreenSync(this.props.user.id);
    }
  }
  render () {
    let {paygreen, user, loadPaygreenSync, cancelPaygreenTransaction} = this.props;
    let paygreenList = paygreen.datas;

    const getStatusLabel = (transactionStatus) => {
      const status = transactionStatus.split('.')[1]
      switch (status) {
        case 'pending':
          return 'En attente';
        case 'authorized':
          return 'Paiement autorisé';
        case 'captured':
          return 'Payé';
        case 'refunded':
          return 'Remboursé';
        case 'refused':
          return 'Refusé';
        case 'canceled':
          return 'Annulé';
        default:
          return status;
      }
    }

    const isCancellable = (transactionStatus, updated_at) => {
      const status = transactionStatus.split('.')[1]
      if (status === 'pending' && moment().diff(moment(updated_at), 'minutes') > 1) {
        return true
      }
      return false
    }

    const refreshList = () => {
      loadPaygreenSync(user.id);
    };

    if (!paygreenList) {
      return null;
    }

    if (paygreen.loading === true) {
      return <div className='PaygreenList text-center'><br />Chargement...</div>;
    }
    if (paygreenList.length === 0) {
      return <div className='PaygreenList'><br /><div className="alert alert-info">Aucune transaction Paygreen</div></div>;
    }
    return <div className='PaygreenList'>
      <br />
      <Button size="xs" variant="default" onClick={refreshList}><i className="fa-solid fa-rotate"></i> Rafraîchir</Button>
      <Table size="sm" striped hover>
        <thead>
          <tr>
            <th>N° transaction</th>
            <th>Date</th>
            <th>Montant</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
        {paygreenList.map((row, index)=> {
          return <tr key={index}>
            <td>{row.id}</td>
            <td>{moment(row.created_at).format('DD/MM/YYYY hh:mm')}</td>
            <td>{row.amount / 100}€</td>
            <td>{getStatusLabel(row.status)}</td>
            <td>{ isCancellable(row.status, row.updated_at) && 
              <Button
                size="xs"
                variant="primary"
                className="mx-3"
                onClick={() => cancelPaygreenTransaction(row.payment_order_id, user.id)}
              >
                Annuler
              </Button>
            }
            </td>
          </tr>
          })}
        </tbody>
      </Table>
    </div>;
  }
}

InternalPaygreenList.propTypes = {
  "paygreen": PropTypes.object,
  "user": PropTypes.object,
  "loadPaygreenSync": PropTypes.func,
  "cancelPaygreenTransaction": PropTypes.func,
};

export const PaygreenList = connect(
  (state) => ({
    "paygreen": state.support.paygreen,
    "user": state.support.user
  }),
  (dispatch) => {
    return {
      loadPaygreenSync: (id) => {
        return dispatch(act_loadPaygreenSync(id));
      },
      cancelPaygreenTransaction: (paymentOrderId, userId) => {
        return dispatch({
          type: SUPPORT_CANCEL_PAYGREEN_TRANSACTION,
          paymentOrderId: paymentOrderId,
          userId: userId,
        });
      }
    };
  }
)(InternalPaygreenList);
